import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`I’m a student studying Graphics and 3D design. Although my work is inspired by observation and imagination, it is heavily influenced by my emotional processing. Much of what I create is representative of my emotional state. I work with a variety of mediums.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      